<template>
  <!-- <div class="headticket">
    <v-container class="viewticket">
          <div class="card-top-frame">
      <p class="farename">{{ farename }}</p>
      <p class="farerule">{{ farerule }}</p>
      <p class="fareexp">{{ fareexp }}</p>
    </div>
    <div class="flex-wrapper-one">
      <div class="frame-11">
        <p class="quan-twan">{{ passengername }}</p>
        <p class="vare-value">{{ farevalue }}</p>
      </div>
      <img
        alt=""
        class="vector"
        :src="require('@/assets/myticket/mobileqr-img.svg')"
      />
      <v-btn small rounded class="button primary" v-on:click="scanQR()" >{{$t('ScanQRCode') }}
        </v-btn>       
         
    </div>
    </v-container>
  </div> -->
  <div align="center" class="head">
    <div align="right">
      <v-container>

      <CardResultBuyFlatFare
      :onHandlerScanQR="clickScanQR"
        :disabledBtnHome="true"
        :orig="item.orig_loc"
        :original="item.original_loc"
        :expticket="item.ticket_expired_date"
        :route="item.route_name"
        :dest="item.dest_loc"
        :destination="item.destination_loc"
        :passenger="item.psg_name"
        :passengername="item.psg_name"
        :price="item.fare_value"
        :faretype="item.fare_type"
        :farename="item.fare_name"
        :item="item"
        :currency="item.fare_currency"
      />
      <!-- <CardResultBuyTicketMultidays
      :onHandlerScanQR="clickScanQR"
        :disabledBtnHome="true"
        :orig="item.orig_loc"
        :original="item.original_loc"
        :expticket="item.ticket_expired_date"
        :route="item.route_name"
        :dest="item.dest_loc"
        :destination="item.destination_loc"
        :passenger="item.psg_name"
        :passengername="item.psg_name"
        :price="item.fare_value"
        :currency="item.fare_currency"
      /> -->

      </v-container>
  </div>
      
  </div>
 
</template>


<script>

import CardResultBuyFlatFare from '@/components/ResultBuyTicket.vue'
// import CardResultBuyTicketMultidays from '@/components/ResultBuyTicketMultidays.vue'

export default {    
  name: "ViewTicket",
  components: {
    CardResultBuyFlatFare,
    // CardResultBuyTicketMultidays,
    },
  data() {
    return {
      item:this.$route.params.data,
      // ticktoken:this.$route.params.data.ticket_token ,
      // farename:this.$route.params.data.fare_name ,
      // farerule:this.$route.params.data.max_trip ,
      // fareexp:this.$route.params.data.ticket_expired_date ,
      // passengername:this.$route.params.data.psg_name ,
      // farevalue:this.$route.params.data.fare_value,
    }
  },
  methods:{
    clickScanQR() {
      // this.$emit("onClickView", this.params.value)
      this.$router.push({
        name: 'ScanQR',
        params: {
          data:this.$route.params.data.ticket_token
        }
      })
    },

    // clickView() {
      
    //   this.onClickView
    //   console.log("okok")
    // },
    // clickNo(){
    //   this.dialog = false
    // },
  },
  mounted(){
    console.log(this.item);
        if(this.item==undefined){
          this.$router.go(-1)
        }
  }
};
</script>

<style lang="scss" scoped>

.head{
  overflow: hidden;
}
</style>